<template>
  <div>
    <div class="operationControl" v-if="btnShow">
      <el-button type="primary" class="bgc-bv" round @click="relationCourse()"
        >关联课程</el-button
      >
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
          />
          <el-table-column
            label="课程名称"
            align="center"
            show-overflow-tooltip
            prop="courseName"
            width="200"
          />
          <el-table-column
            label="培训类型"
            align="center"
            show-overflow-tooltip
            prop="trainTypeNamePath"
          />
          <el-table-column
            label="课件数量"
            align="center"
            show-overflow-tooltip
            prop="kpointTotalNum"
          />
          <el-table-column
            label="关联时间"
            align="center"
            show-overflow-tooltip
            prop="createTime"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime || momentDate }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" v-if="btnShow">
            <template slot-scope="scope">
              <el-button
                type="text"
                style="padding: 0px 5px"
                size="mini"
                @click="handleRemove(scope.row.compCourseId)"
                >移除</el-button
              >
            </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <relationCourseList
      ref="relationCourseList"
      :orderId="orderId"
      @eventBus="relationCourseBack"
    />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import moment from "moment";
import relationCourseList from "@/views/CustomPrograms/relationCourseList.vue";

export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
    relationCourseList,
  },
  mixins: [List],
  props: {
    //是否显示按钮
    btnShow: {
      type: Boolean,
      default: false,
    },
    //订单Id
    orderId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    //获取列表数据
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        orderId: this.orderId,
      };
      this.doFetch({
        url: "/biz/et/order/pageListCompCourse",
        params,
        pageNum,
      });
    },
    //关联课程
    relationCourse() {
      this.$refs.relationCourseList.showPopUp();
    },
    //关联课程回调
    relationCourseBack(ret) {
      let courseIds = [];
      ret.map((el) => {
        courseIds.push(el.courseId);
      });
      this.$post("/biz/et/order/saveOrUpdateCourse", {
        courseIds,
        orderId: this.orderId,
      }).then((res) => {
        if (res.status == "0") {
          this.$message.success(res.message);
          this.getData(-1);
        }
      });
    },
    //移除课程
    handleRemove(compCourseId) {
      this.$post("/biz/et/order/removeCompCourse", { compCourseId }).then(
        (res) => {
          if (res.status == "0") {
            this.$message.success(res.message);
            this.getData(-1);
          }
        }
      );
    },
    //获取列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
</style>
