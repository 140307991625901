<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="2%"
    width="55%"
    title="关联试卷"
    :center="true"
    @close="dialogClose"
    :close-on-click-modal="false"
  >
    <section class="">
      <div class="operationControl" style="border: 0">
        <div class="searchbox">
          <div title="试卷名称" class="searchboxItem">
            <span class="itemLabel">试卷名称:</span>
            <el-input
              clearable
              v-model="paperName"
              type="text"
              size="small"
              placeholder="请输入试卷名称"
            />
          </div>

          <div title="培训类型" class="searchboxItem">
            <span class="itemLabel">培训类型:</span>
            <el-cascader
              v-model="trainTypeId"
              :options="TrainTypeList"
              filterable
              clearable
              :show-all-levels="false"
              :props="{
                emitPath: false,
                value: 'id',
                label: 'label',
                children: 'children',
                checkStrictly: true,
              }"
              size="small"
            ></el-cascader>
          </div>
          <div>
            <el-button class="bgc-bv" @click="getData()">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            row-key="paperId"
            style="width: 100%"
            @selection-change="change"
            stripe
            border
          >
            <el-table-column
              type="selection"
              width="50px"
              align="center"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              label="试卷名称"
              align="center"
              prop="paperName"
              show-overflow-tooltip
            />
            <el-table-column
              label="试题数量"
              align="center"
              prop="questionNum"
              show-overflow-tooltip
            />

            <el-table-column
              label="培训类型"
              align="center"
              prop="trainTypeNamePath"
              show-overflow-tooltip
            />
            <el-table-column label="创建时间" align="center" width="120">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.createTime | momentDate }}
                </span>
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <div class="dialog-footer" style="margin-top: 10px">
        <div>
          <p>
            已选{{ backArr.length }}门,<el-button
              type="text"
              @click="emptySelected"
              >清空已选</el-button
            >
          </p>
        </div>
        <div>
          <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </div>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "relationCourseList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: {
    //订单Id
    orderId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      paperName: "", //试卷名称
      trainTypeId: "", //培训类型Id
      TrainTypeList: [], //培训类型数据
      backArr: [], //table表格选中数据
      dialogVisible: false, //弹窗显示
    };
  },
  created() {
    this.getTableHeight();
  },
  updated() {},
  methods: {
    //table表格选择事件
    change(rowArr) {
      this.backArr = rowArr;
    },
    //弹窗显示
    showPopUp() {
      this.dialogVisible = true;
      this.getData(-1);
      this.getTrainTypeArrData();
    },
    //获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        orderId: this.orderId,
      };
      if (this.paperName) {
        params.paperName = this.paperName;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }
      let url = "/biz/et/order/pageListPaper";
      this.doFetch({
        url: url,
        params,
        pageNum,
      });
    },
    //获取培训类型数据
    getTrainTypeArrData() {
      this.$post("/sys/category/train-type/enterprise", {}).then((ret) => {
        this.TrainTypeList = ret.data;
      });
    },

    //弹窗确认
    doOk() {
      const { backArr } = this;
      if (backArr.length) {
        this.$emit("eventBus", backArr);
        this.dialogClose();
      } else {
        this.$message({
          message: "请选择要关联的试卷",
          type: "warning",
        });
      }
    },
    //关闭弹窗
    dialogClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
    },
    //清空已选
    emptySelected() {
      this.backArr = [];
      this.$refs.multipleTable.clearSelection();
    },
    //获取表格高度
    getTableHeight(opDom = true, page = true) {
      let tHeight = 700 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
</style>
